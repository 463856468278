$font-path: "../fonts/" !default;

/* inter-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('#{$font-path}inter-v13-latin-regular.woff2') format('woff2');
}
/* inter-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('#{$font-path}inter-v13-latin-700.woff2') format('woff2');
}